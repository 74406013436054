<template>
  <mobile-screen :header="true" screen-class="icon-app1 hidden-screen">
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
export default {
  components: {
    MobileScreen
  }
};
</script>
